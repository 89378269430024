import React from 'react'
import { useAuth } from '../hooks/useAuth'
import api from '../lib/api'
import { useQuery } from '@tanstack/react-query'

const Dashboard = () => {
    const { user, logout } = useAuth()



    const { data: dashboardStats } = useQuery({
        queryKey: ["get-dashboard-stats"],
        queryFn: () => api.get("web/get-dashboard-stats"),
        select: (res) => res.data.data,
    });




    return (

        <>


            <main className="content">
                <a id="btn-toggle" href="#" className="sidebar-toggler break-point-sm">
                    <i className="ri-menu-line ri-xl"></i>
                </a>
                <div className="container mt-5 px-44">
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="text-slate mb-4">Dashboard</h4>
                        </div>




                        <div className="col-lg-4">
                            <div className="card border-0 mb-3">
                                <div className="card-body">
                                    <div className="row align-items-center">
                                        <div className="col-9">
                                            <h2 className="thm-color fw-bolder">{dashboardStats?.communitiesCount}</h2>
                                            <p className="text-slate ">All Communities</p>
                                        </div>
                                        <div className="col-3">
                                            <img src="/images/user.svg" alt="image" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card border-0 mb-3">
                                <div className="card-body">
                                    <div className="row align-items-center">
                                        <div className="col-9">
                                            <h2 className="thm-color fw-bolder">{dashboardStats?.eventsCount}</h2>
                                            <p className="text-slate">Total Events</p>
                                        </div>
                                        <div className="col-3">
                                            <img src="/images/note.svg" alt="image" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="card border-0 mb-3">
                                <div className="card-body">
                                    <div className="row align-items-center">
                                        <div className="col-9">
                                            <h2 className="thm-color">{dashboardStats?.violationsCount}</h2>
                                            <p>Total Violations</p>
                                        </div>
                                        <div className="col-3">
                                            <img src="/images/violation.svg" alt="image" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </main>
        </>

    )
}

export default Dashboard