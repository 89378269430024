import cogoToast from 'cogo-toast'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import api from '../lib/api'
import { useMutation } from '@tanstack/react-query'

const ResetPassword = () => {
   const { state } = useLocation()


   const navigate = useNavigate()

   const {
      register,
      handleSubmit,
      watch,
      formState: { errors },
   } = useForm()


   const { mutate } = useMutation({
      mutationFn: (data) => api.post("/auth/reset-password", data),

      onError: (data, error, variables, context) => {
         // An error happened!
         cogoToast.error(data?.response?.data?.message);
         console.log(`rolling back optimistic update with id ${context.id}`);
      },
      onSuccess: (data, variables, context) => {
         console.log("variable", variables);
         if (data.data.status == 200) {
            cogoToast.success("success");
            setTimeout(() => {
               navigate('/login')
            }, 2000)
         }
      },

   });




   useEffect(() => {
      if (!state?.email) {
         navigate('/')
      }

   }, [state])


   const onSubmit = (data) => {
      if (data.password !== data.cpassword) {
         cogoToast.error('password doesn"t match')

      } else {
         mutate({
            email: state?.email, password: data.password
         })
      }

   }

   return (
      <div className="container-fluid">
         <div className="row">
            <div className="col-lg-6 px-md-0">
               <div className="px-lg-5 px-md-3 mt-4">
                  <img src="/images/logo.svg" alt="logo" />
               </div>

               <div className="row justify-content-center mt-4 mx-0">
                  <div className="col-xl-8 col-lg-10 col-md-8">
                     <div className="card border-0 common">
                        <div className="card-body">
                           <h2 className="text-black">Reset Password</h2>
                           <p className="text-mute my-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                           <form onSubmit={handleSubmit(onSubmit)} >
                              <div className="mb-4">
                                 <label htmlFor="password" className="form-label fw-bolder text-label">New Password</label>
                                 <input type="password" className="form-control" placeholder="Enter New Password" id="password" {...register("password", {
                                    required: "Password is required", validate: (value) => {
                                       if (
                                          value.length < 8 ||
                                          !/[A-Z]/.test(value) ||
                                          !/[a-z]/.test(value) ||
                                          !/[!@#$%^&*()\-=_+{};':",.<>/?[\]\\|]/.test(value)
                                       ) {
                                          return 'Password must include atleast one lowercase letter, one uppercase letter, one number and one special character and must be atleast 8 characters long'

                                       }

                                    }
                                 })} />
                                 {errors.password && <p style={{ color: 'red' }} role="alert">{errors.password.message}</p>}

                              </div>
                              <div className="mb-5">
                                 <label htmlFor="cn-password" className="form-label fw-bolder text-label">Confirm New Password</label>
                                 <input type="password" className="form-control" placeholder="Enter Confirm Password" id="cn-password" {...register("cpassword", { required: "confirm password is required" })} />
                                 {errors.cpassword && <p style={{ color: 'red' }} role="alert">{errors.cpassword.message}</p>}

                              </div>
                              <a href="#">
                                 <button type="submit" className="btn btn-login btn-block w-100">Submit</button>
                              </a>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div className="col-lg-6 px-0 d-None">
               <div className="bg-login">
                  <img src="/images/login.png" alt="login" className="img-fluid w-75" />
               </div>
            </div>
         </div>
      </div>
   )
}

export default ResetPassword