import { createContext, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "./useCookies";
import { useEffect } from "react";

const commonContext = createContext();

export const CommonProvider = ({ children }) => {
    const [selectedUsers, setSelectedUsers] = useState([])
    const [data, setData] = useState([])
    const navigate = useNavigate();


    const value = useMemo(
        () => ({
            selectedUsers, setSelectedUsers, data, setData
        }),
        [selectedUsers, data]
    );

    return <commonContext.Provider value={value}>{children}</commonContext.Provider>;
};

export const useCommon = () => {
    return useContext(commonContext);
};
