import * as React from "react";

import { useMutation } from "@tanstack/react-query";
import api from "../lib/api";
import cogoToast from "cogo-toast";
import { useAuth } from "../hooks/useAuth";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useNavBar } from "../hooks/useNavBarContext";
import { InputGroup } from "react-bootstrap";
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";


export default function SignIn() {
   const { login, user } = useAuth();
   const { community, setCommunity } = useNavBar()
   const [showPass, setShowPass] = React.useState(false)
   const [rememberMe, setRememberMe] = React.useState(false)

   const navigate = useNavigate()

   const {
      register,
      handleSubmit,
      watch,
      formState: { errors },
   } = useForm()



   React.useEffect(() => {
      if (user) {
         navigate('/')
      }

   }, [user])




   const { mutate } = useMutation({
      mutationFn: (data) => api.post("/auth/login", data),


      onError: (error, variables, context) => {
         // An error happened!
         cogoToast.error("Username and Password not match");
      },
      onSuccess: (data, variables, context) => {
         if (data.data.status == 200) {
            cogoToast.success("success");
            setCommunity(data.data.data.community)
            login(data.data.data);
         }
      },

   });

   const onSubmit = (data) => {
      let body = {
         ...data, rememberMe
      }
      mutate(body)

   };

   return (
      <div className="container-fluid">
         <div className="row">
            <div className="col-lg-6 px-md-0">
               <div className="px-lg-5 px-md-3 mt-4">
                  <img src="/images/hoa_admin_panel_logo.png" width={150} alt="logo" />
               </div>

               <div className="row justify-content-center mt-4 mx-0">
                  <div className="col-xl-8 col-lg-10 col-md-8">
                     <div className="card border-0 common">
                        <div className="card-body">
                           <h2 className="text-black">H.O.A Login</h2>
                           <p className="text-mute my-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                           <form onSubmit={handleSubmit(onSubmit)}>
                              <div className="mb-4">
                                 <label htmlFor="email" className="form-label fw-bolder text-label">Email</label>
                                 <input type="email" className="form-control" placeholder="Enter Email" id="email" {...register("email", { required: "email is required" })} />
                                 {errors.email && <p style={{ color: 'red' }} role="alert">{errors.email.message}</p>}

                              </div>

                              <div className="mb-4">
                                 <label htmlFor="exampleInputPassword" className="form-label fw-bolder text-label">Password</label>
                                 <InputGroup>
                                    {/* <Form.Control aria-label="Dollar amount (with dot and two decimal places)" /> */}
                                    <input type={showPass ? "text" : "password"} className="form-control" placeholder="Enter Password" id="exampleInputPassword" {...register("password", {
                                       required: "Password is required", validate: (value) => {
                                          if (
                                             value.length < 8 ||
                                             !/[A-Z]/.test(value) ||
                                             !/[a-z]/.test(value) ||
                                             !/[!@#$%^&*()\-=_+{};':",.<>/?[\]\\|]/.test(value)
                                          ) {
                                             return 'Password must include atleast one lowercase letter, one uppercase letter, one number and one special character and must be atleast 8 characters long'

                                          }

                                       }
                                    })} />

                                    <InputGroup.Text onClick={() => {
                                       setShowPass(!showPass)
                                    }} >{showPass ? <IoEye /> : <IoEyeOff />} </InputGroup.Text>
                                 </InputGroup>
                              </div>

                              {/* <div className="mb-4">
                                 <label htmlFor="password" className="form-label fw-bolder text-label">Password</label>
                                 <input type="password" className="form-control" placeholder="Enter Password" id="exampleInputPassword" {...register("password", {
                                    required: "Password is required", validate: (value) => {
                                       if (
                                          !/[A-Z]/.test(value)
                                       ) {
                                          return 'Password must include atleast one lowercase letter, one uppercase letter, one number and one special character and must be atleast 8 characters long'

                                       }

                                    }
                                 })} />
                                 {errors.password && <p style={{ color: 'red' }} role="alert">{errors.password.message}</p>}

                              </div> */}
                              <div className="clearfix mb-5">
                                 <div className="float-start">
                                    <div className="form-check">
                                       <input type="checkbox" onChange={(e) => { setRememberMe(e.target.checked) }} className="form-check-input" id="exampleCheck1" />
                                       <label className="form-check-label color" htmlFor="exampleCheck1">Remember me</label>
                                    </div>
                                 </div>
                                 <div className="float-end">
                                    <a onClick={() => {
                                       navigate('/forgot-password')
                                    }} className="text-black color text-decoration-none">Forgot your password? </a>
                                 </div>
                              </div>
                              <a href="#">
                                 <button type="submit" className="btn btn-login btn-block w-100">Login</button>
                              </a>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>

               <p className="text-center">Don't have an Account? <a onClick={() => {
                  navigate('/register')
               }} className="thm-color fw-bolder text-decoration-none">Register</a></p>
            </div>

            <div className="col-lg-6 px-0 d-None">
               <div className="bg-login">
                  <img src="/images/login.png" alt="login" className="img-fluid w-75" />
               </div>
            </div>
         </div>
      </div>
   );
}