import * as React from "react";

import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import api from "../lib/api";
import cogoToast from "cogo-toast";
import { useAuth } from "../hooks/useAuth";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { IoEye } from "react-icons/io5";
import { IoEyeOff } from "react-icons/io5";
import { InputGroup } from "react-bootstrap";

export default function SignUp() {
   const { login, user } = useAuth();
   const navigate = useNavigate()
   const [code, setCode] = useState('')
   const [phone, setPhone] = useState('')
   const [flag, setFlag] = useState('')
   const [showPass, setShowPass] = React.useState(false)
   const [rememberMe, setRememberMe] = React.useState(false)
   const { mutate } = useMutation({
      mutationFn: (data) => api.post("/auth/register", data),
      onMutate: (variables) => {
         // A mutation is about to happen!

         // Optionally return a context containing data to use when for example rolling back
         return { id: 1 };
      },

      onError: (data, error, variables, context) => {
         // An error happened!
         console.log('data error', data)
         if (data.response.data.message) {

            cogoToast.error(`${data.response.data.message}`);
         } else {
            cogoToast.error(`server error`);

         }


      },
      onSuccess: (data, variables, context) => {
         console.log("variable", variables);
         if (data.data.status == 201) {
            cogoToast.success(`${data.data.message}`);
            setTimeout(() => {

               navigate('/login')
            }, 2000)
         }
      },
      onSettled: (data, error, variables, context) => {
         // Error or success... doesn't matter!
      },
   });


   const {
      register,
      handleSubmit,
      watch,
      formState: { errors },
   } = useForm()


   React.useEffect(() => {
      if (user) {
         navigate('/')
      }

   }, [user])

   const onSubmit = (data) => {
      console.log(data)
      if (!phone) {
         cogoToast.error('Mobile number is not entered')
         return
      }
      let dialCode = code
      let withoutCodeNumber = phone.slice(code.length)


      const body = {
         name: data.name,
         email: data.email,
         password: data.password,
         phone: withoutCodeNumber,
         dialCode: `+${dialCode}`,
         flag: flag,
         address: {
            address_line_1: data.address1,
            address_line_2: data.address2,
            city: data.city,
            state: data.state,
            zip: data.zipCode,


         }


      }


      mutate(body)
   };

   return (
      <div className="container-fluid">
         <div className="row">
            <div className="col-lg-6 px-md-0">
               <div className="px-lg-5 px-md-3 mt-4" >
                  <Link to="/">

                     <img src="/images/hoa_admin_panel_logo.png" width={150} alt="logo" />

                  </Link>
               </div>

               <div className="row justify-content-center mt-4 mx-0">
                  <div className="col-xl-8 col-lg-10 col-md-8">
                     <div className="card border-0 common">
                        <div className="card-body">
                           <h2 className="text-black">Create Account</h2>
                           <p className="text-mute my-4">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                           <form onSubmit={handleSubmit(onSubmit)}>
                              <div className="mb-4">
                                 <label htmlFor="Name" className="form-label fw-bolder text-label">Name</label>
                                 <input type="text" className="form-control" placeholder="Enter Name" id="Name" {...register("name", { required: "Name is required" })} />
                                 {errors.name && <p style={{ color: 'red' }} role="alert">{errors.name.message}</p>}

                              </div>
                              <div className="mb-4">
                                 <label htmlFor="email" className="form-label fw-bolder text-label">Email</label>
                                 <input type="email" className="form-control" placeholder="Enter Email" id="email" {...register("email", { required: "Email is required" })} />
                                 {errors.email && <p style={{ color: 'red' }} role="alert">{errors.email.message}</p>}

                              </div>



                              <div className="mb-4">
                                 <label htmlFor="exampleInputPassword" className="form-label fw-bolder text-label">Password</label>
                                 <InputGroup>
                                    {/* <Form.Control aria-label="Dollar amount (with dot and two decimal places)" /> */}
                                    <input type={showPass ? "text" : "password"} className="form-control" placeholder="Enter Password" id="exampleInputPassword" {...register("password", {
                                       required: "Password is required", validate: (value) => {
                                          if (
                                             value.length < 8 ||
                                             !/[A-Z]/.test(value) ||
                                             !/[a-z]/.test(value) ||
                                             !/[!@#$%^&*()\-=_+{};':",.<>/?[\]\\|]/.test(value)
                                          ) {
                                             return 'Password must include atleast one lowercase letter, one uppercase letter, one number and one special character and must be atleast 8 characters long'

                                          }

                                       }
                                    })} />

                                    <InputGroup.Text onClick={() => {
                                       setShowPass(!showPass)
                                    }} >{showPass ? <IoEye /> : <IoEyeOff />} </InputGroup.Text>
                                 </InputGroup>
                              </div>


                              {/* <div className="mb-5">
                                 <label htmlFor="password" className="form-label fw-bolder text-label">Password</label>
                                 <input type="password" className="form-control" placeholder="Enter Password" id="exampleInputPassword" {...register("password", {
                                    required: "Password is required", validate: (value) => {
                                       if (
                                          value.length < 8 ||
                                          !/[A-Z]/.test(value) ||
                                          !/[a-z]/.test(value) ||
                                          !/[!@#$%^&*()\-=_+{};':",.<>/?[\]\\|]/.test(value)
                                       ) {
                                          return 'Password must include atleast one lowercase letter, one uppercase letter, one number and one special character and must be atleast 8 characters long'

                                       }

                                    }
                                 })} />
                                 {errors.password && <p style={{ color: 'red' }} role="alert">{errors.password.message}</p>}

                              </div> */}

                              <div className="mb-4">
                                 <label className="form-label">Phone</label>

                                 <PhoneInput
                                    country={'in'}
                                    // value={this.state.phone}
                                    onChange={(value, country) => {
                                       setCode(country.dialCode)

                                       setPhone(value)
                                       setFlag(country.name)
                                    }}
                                    enableSearch={true}
                                 />



                              </div>



                              <div className="col-md-12">
                                 <div className="mb-4">
                                    <label className="form-label">Address</label>
                                    <input type="text" className="form-control mb-4" placeholder="Enter Address 1" {...register("address1", { required: "Address Line 1 is required" })} />

                                    {errors.address1 && <p style={{ color: 'red' }} role="alert">{errors.address1.message}</p>}

                                    <input type="text" className="form-control mb-4" placeholder="Enter Address 2" {...register("address2")} />

                                    {errors.address2 && <p style={{ color: 'red' }} role="alert">{errors.address2.message}</p>}


                                    <input type="text" className="form-control mb-4" placeholder="State" {...register("state", { required: "state is required" })} />
                                    {errors.state && <p style={{ color: 'red' }} role="alert">{errors.state.message}</p>}

                                    <input type="text" className="form-control mb-4" placeholder="City" {...register("city", { required: "city is required" })} />
                                    {errors.city && <p style={{ color: 'red' }} role="alert">{errors.city.message}</p>}

                                    <input type="text" className="form-control mb-4" placeholder="Zip Code" {...register("zipCode", { required: "city is required" })} />
                                    {errors.zipCode && <p style={{ color: 'red' }} role="alert">{errors.zipCode.message}</p>}


                                 </div>
                              </div>




                              <a href="#">
                                 <button type="submit" className="btn btn-login btn-block w-100">Register</button>
                              </a>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>

               <p className="text-center">Already have an Account? <a onClick={() => {
                  navigate('/login')
               }} className="thm-color fw-bolder text-decoration-none">Login</a></p>
            </div>

            <div className="col-lg-6 px-0 d-None">
               <div className="bg-login">
                  <img src="/images/login.png" alt="login" className="img-fluid w-75" />
               </div>
            </div>
         </div>
      </div>
   );
}
