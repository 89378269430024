import { useMutation, useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import cogoToast from 'cogo-toast'
import { useForm } from 'react-hook-form'
import api from '../../../lib/api'
import MultiSelectPicker from '../../../component/MultiSelectPicker'
import { useCommon } from '../../../hooks/useCommon'

const AddGlobalAnnouncements = ({ show, setShow, refetch }) => {
    const [isAll, setIsAll] = useState(false)
    const { data, setData, selectedUsers, setSelectedUsers } = useCommon()
    const handleClose = () => {

        setShow(false)

    }



    const {
        register,
        handleSubmit,
        watch,
        reset,
        setValue,
        formState: { errors },
    } = useForm()





    const { mutate } = useMutation({
        mutationFn: (data) => api.post("/web/add-global-announcements", data),


        onError: (data, error, variables, context) => {
            // An error happened!
            if (data.response.data.message) {

                cogoToast.error(`${data.response.data.message}`);
            } else {
                cogoToast.error(`server error`);

            }


        },
        onSuccess: (data, variables, context) => {
            console.log("variable", variables);
            if (data.data.status == 201) {
                cogoToast.success(`${data?.data?.message}`);
                setTimeout(() => {
                    setShow(false)
                }, 1000)
                refetch()
            }
        },

    });



    const { data: getCommunities } = useQuery({
        queryKey: ["get-community"],
        queryFn: () => api.get("common/get-community"),
        select: (res) => res.data.data.result,
    });




    const onSubmit = (data) => {
        const body = {
            title: data.title,
            message: data.message,
            dateAndTime: data.dateAndTime,
            announcementType: isAll ? "Global" : "Community",
            community: selectedUsers


        }

        console.log(body)



        mutate(body)
    }



    useEffect(() => {
        if (isAll) {

            setData(() => {
                let temp = getCommunities?.map((item) => {
                    return { label: item.name, value: item._id }
                })
                return [...temp]

            })

            setSelectedUsers(() => {
                let temp = getCommunities?.map((item) => item._id)
                return [...temp]

            })

        } else {
            setSelectedUsers([])
            setData([])
        }


    }, [isAll])




    return (
        <Modal show={show} onHide={handleClose}>

            <Modal.Body>
                <main className="content">
                    <div>
                        {/* <a id="btn-toggle" href="#" className="sidebar-toggler break-point-sm">
                            <i className="ri-menu-line ri-xl"></i>
                        </a> */}
                        <div className="container px-44">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="card border-0 py-4 px-2">
                                        <h4 className="text-slate mb-3">Add Global Announcements</h4>
                                        <div className="card-body px-0">
                                            <form className="common-form" onSubmit={handleSubmit(onSubmit)} >
                                                <div className="mb-4">
                                                    <label className="form-label">Title</label>
                                                    <input type="text" className="form-control" placeholder="Enter Title" {...register("title", { required: "Title is required" })} />
                                                    {errors.title && <p style={{ color: 'red' }} role="alert">{errors.title.message}</p>}

                                                </div>
                                                <div className="mb-4">
                                                    <label className="form-label">Message</label>
                                                    <input type="text" className="form-control" placeholder="Enter Message" {...register("message", { required: "message is required" })} />
                                                    {errors.message && <p style={{ color: 'red' }} role="alert">{errors.message.message}</p>}

                                                </div>

                                                <div className="mb-4">
                                                    <label className="form-label">Date</label>
                                                    <input type="date" className="form-control"  {...register("dateAndTime")} />
                                                    {errors.dateAndTime && <p style={{ color: 'red' }} role="alert">{errors.dateAndTime.message}</p>}

                                                </div>

                                                <div className="mb-4">
                                                    <label className="form-label">Select Communities</label>
                                                    <MultiSelectPicker mapData={getCommunities} hold={false} limit={getCommunities?.length} />
                                                    {errors.dateAndTime && <p style={{ color: 'red' }} role="alert">{errors.dateAndTime.message}</p>}

                                                </div>


                                                <Form.Check
                                                    type="checkbox"
                                                    id="default"
                                                    label={`Is Global`}
                                                    onChange={(e) => {
                                                        setIsAll(e.target.checked)
                                                    }}
                                                    checked={isAll}
                                                />




                                                <button type="submit" className="btn btn-main Shadow">Submit</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </Modal.Body>




        </Modal>
    )
}

export default AddGlobalAnnouncements