import React, { useState } from 'react'
import { useAuth } from '../hooks/useAuth'
import { useLocation } from 'react-router-dom'
import { useMutation, useQuery } from '@tanstack/react-query'
import api from '../lib/api'
import { useNavBar } from '../hooks/useNavBarContext'
import cogoToast from 'cogo-toast'

const NavBar = () => {
    const { user, logout } = useAuth()
    const { community, setCommunity, setSearch, search } = useNavBar()
    const location = useLocation()
    const { data: getCommunities } = useQuery({
        queryKey: ["get-community"],
        queryFn: () => api.get("common/get-community"),
        select: (res) => res.data.data.result,
    });







    console.log('community value', community)



    return (
        <>

            <nav className="navbar navbar-expand-md bg-white sticky-top">
                <div className="container-fluid">
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <form className="d-flex" role="search">
                        <div className="ref-image">
                            <div className="input-group br">

                                <input type="text" className="form-control" placeholder="Search..." onChange={(e) => {

                                    setSearch(e.target.value)

                                }} />
                                <span className="input-group-text bg-white  border-0"><img src="/images/search.svg" alt="search" /></span>
                            </div>
                        </div>
                    </form>


                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto">

                            {
                                location.pathname == "/" || location.pathname == "/documents" || location.pathname == "/folders" ? (
                                    <></>

                                ) : (

                                    <li className="nav-item">
                                        <select name="" id="community" className="community-dropdown form-check" value={community} onChange={(e) => {
                                            setCommunity(e.target.value)
                                        }} >
                                            <option value="#">--select community--</option>

                                            {
                                                getCommunities?.map((item) => {
                                                    return (


                                                        <option value={item._id}>{item.name}</option>
                                                    )


                                                })


                                            }

                                        </select>
                                    </li>

                                )

                            }
                            {/* <li className="nav-item dropdown">

                                <select name="" id="" onChange={(e) => {
                                    if (e.target.value == 'logout') {
                                        logout()
                                    }
                                }} className="border-0 community-dropdown form-check text-capitalize">
                                    <option value=""><a className="nav-link dropdown-toggle thm-color fw-bolder" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src="/images/profile.jpg" alt="profile" className="img-fluid profile-img" /> {user?.name}
                                    </a></option>

                                    <option value="profile"><a className="dropdown-item" href="">Profile</a></option>
                                    <option value="logout"><a className="dropdown-item" >Logout</a></option>
                                </select>

                                <ul className="dropdown-menu">
                                    <li></li>
                                    <li><a className="dropdown-item" href="#">Profile</a></li>
                                    <li><a className="dropdown-item" onClick={() => logout()}>Logout</a></li>
                                </ul>
                            </li> */}
                        </ul>
                    </div>
                </div>
            </nav>



        </>
    )
}

export default NavBar