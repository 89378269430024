import { useMutation, useQuery } from '@tanstack/react-query'
import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import api from '../../../lib/api'
import cogoToast from 'cogo-toast'
import { useForm } from 'react-hook-form'

const ViewUsers = ({ show, setShow, viewData }) => {

    const handleClose = () => {

        setShow(false)

    }



    console.log(viewData)



    return (
        <Modal show={show} onHide={handleClose}>

            <Modal.Body>
                <main className="content">
                    <div>
                        {/* <a id="btn-toggle" href="#" className="sidebar-toggler break-point-sm">
                            <i className="ri-menu-line ri-xl"></i>
                        </a>
                                */}



                    </div>

                    <div className="container px-44">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card border-0 py-4 px-2">
                                      <h4 className="text-slate mb-3">View User Details </h4>
                                        <div className="card-body px-0">

                                            <div className="row">

                                            <div className="col-6 mb-3">
                                            <span className="fw-semibold">Name -</span>
                                            
                                               <span className="float-end">{viewData?.name}</span>
                                                </div>

                                                <div className="col-6 mb-3">
                                                <span className="fw-semibold">Email -</span>
                                                <span className="float-end"> {viewData?.email}</span>
                                            </div>

                                            <div className="col-6 mb-3">
                                            <span className="fw-semibold">Phone -</span> 
                                            <span className="float-end">{viewData?.phone}</span>
                                               </div>

                                               <div className="col-6 mb-3">
                                               <span className="fw-semibold">Role -</span> 
                                               <span className="float-end">{viewData?.role}</span>

                                               </div>

                                               <div className="col-6 mb-3">
                                                <span className="fw-semibold">isApproved -</span>
                                                <span className="float-end"> {viewData?.isApproved ? 'Yes' : 'No'}</span>
                                               </div>

                                               <div className="col-6 mb-3">
                                               <span className="fw-semibold">is Active -</span> 
                                               <span className="float-end">{viewData?.isActive ? 'Yes' : 'No'}</span>
                                               </div>

                                              
                                                
                                            </div>

                                        <div>
                        

                    </div>
                  
                    <button className="btn btn-main Shadow mt-3" onClick={handleClose}>
                    close
                </button>
                   
                   

                                        </div>
                                       
                                    </div>
                                    
                                </div>
                            </div>
                     </div>
                     
                   


                </main>
                
            </Modal.Body>
           
        </Modal>
    )
}

export default ViewUsers