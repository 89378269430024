import { useMutation } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import api from '../../lib/api'
import cogoToast from 'cogo-toast'
import TablePagination from '../../component/TablePagination'
import { Form } from 'react-bootstrap'
import FormCheckLabel from 'react-bootstrap/FormCheckLabel'
import DeleteFolder from '../../component/DeleteData'
import AddDocument from './components/AddDocument'
import EditDocument from './components/EditDocument'
import { useNavBar } from '../../hooks/useNavBarContext'

const Documents = () => {
    const navigate = useNavigate()
    const [filterBtnActive, setFilterBtnActive] = useState('0')
    const [filter, setFilter] = useState('Approvals')
    const [totalPages, setTotalPages] = useState(1)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)


    // edit modal open
    const [editOpen, setEditOpen] = useState(false)
    const [editData, setEditData] = useState({})

    // add modal open
    const [addOpen, setAddOpen] = useState(false)
    const [communities, setCommunities] = useState([])


    // delete modal open
    const [deleteOpen, setDeleteOpen] = useState(false)
    const [deleteData, setDeleteData] = useState({})

    const { search, community } = useNavBar()
    // const filterButtons = ['Active', 'Inactive']



    const { mutate } = useMutation({
        mutationFn: (data) => api.post("/web/get-documents", data),
        onMutate: (variables) => {
            // A mutation is about to happen!

            // Optionally return a context containing data to use when for example rolling back
            return { id: 1 };
        },

        onError: (data, error, variables, context) => {
            // An error happened!
            if (data.response.data.message) {

                cogoToast.error(`${data.response.data.message}`);
            } else {
                cogoToast.error(`server error`);

            }


        },
        onSuccess: (data, variables, context) => {
            console.log("variable", variables);
            setCommunities(data.data.data.result)
            setTotalPages(data.data.data.paginate?.totalPages)
        },
        onSettled: (data, error, variables, context) => {
            // Error or success... doesn't matter!
        },
    });



    // 

    const { mutate: toggle } = useMutation({
        mutationFn: (data) => api.post("/web/toggle-hoa-status", data),
        onMutate: (variables) => {
            // A mutation is about to happen!

            // Optionally return a context containing data to use when for example rolling back
            return { id: 1 };
        },

        onError: (data, error, variables, context) => {
            // An error happened!
            if (data.response.data.message) {

                cogoToast.error(`${data.response.data.message}`);
            } else {
                cogoToast.error(`server error`);

            }


        },
        onSuccess: (data, variables, context) => {
            console.log("variable", variables);
            cogoToast.success(`${data.data.message}`)

            mutate({
                page,
                limit,
                filter,
            })
        },

    });

    // 






    useEffect(() => {
        mutate({
            page,
            limit,
            filter,
            search,
            community: community ?? '#'


        })


    }, [filter, page, limit, editOpen, addOpen, deleteOpen, search, community])












    return (
        <>

            <main className="content">
                {
                    editOpen ? <EditDocument show={editOpen} setShow={setEditOpen} editData={editData} /> : <></>

                }


                {
                    addOpen ? <AddDocument show={addOpen} setShow={setAddOpen} /> : <></>

                }

                {
                    deleteOpen ? <DeleteFolder show={deleteOpen} setShow={setDeleteOpen} data={deleteData} deleteType={"document"} /> : <></>

                }



                <div>
                    <a id="btn-toggle" href="#" className="sidebar-toggler break-point-sm">
                        <i className="ri-menu-line ri-xl"></i>
                    </a>
                    <div className="container px-44">
                        <div className="row">

                            <div className="col-md-12">


                                <div className="tab-content" id="pills-tabContent">

                                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                                        <div className="card border-0 table-card">
                                            <div className="card-body">
                                                <div className="float-end">
                                                    <div className="d-flex">
                                                        <button className="btn btn-main me-2" onClick={() => {
                                                            setAddOpen(true)
                                                        }} >Add Document</button>

                                                    </div>
                                                </div>
                                                <div className="clearfix mb-4 mt-2">
                                                    <div className="float-start">
                                                        <h4 className="thm-color">Documents List</h4>
                                                    </div>
                                                    <div className="float-end">
                                                    </div>
                                                </div>
                                                <div className="table-responsive">
                                                    <table className="table table-striped mb-0 align-middle common-table">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">S no.</th>
                                                                <th scope="col">Name</th>
                                                                <th scope="col">Folder Name</th>




                                                                <th scope="col">Action</th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                communities?.map((item, index) => {
                                                                    return (

                                                                        <tr key={item._id} >
                                                                            <th scope="row">{index + 1}</th>
                                                                            <td>{item?.name}</td>
                                                                            <td>{item?.folderDetails?.name}</td>




                                                                            <td style={{ display: 'flex' }}>
                                                                                <button className="btn btn-green me-3" onClick={() => {
                                                                                    setEditOpen(true)
                                                                                    setEditData(item)

                                                                                }} ><img src="/images/edit.svg" alt="edit" className="img-fluid" /></button>

                                                                                <button className="btn btn-red me-3" onClick={() => {
                                                                                    setDeleteOpen(true)
                                                                                    setDeleteData(item)

                                                                                }} ><img src="/images/delete.svg" alt="delete" className="img-fluid" /></button>

                                                                                {/* <Form.Check
                                                                                    onChange={(e) => {
                                                                                        toggle({
                                                                                            id: item._id,
                                                                                            status: !item.isActive

                                                                                        })
                                                                                    }}
                                                                                    checked={item.isActive}
                                                                                    type="switch"
                                                                                    className="me-3"
                                                                                    id={`custom-switch${item._id}`}
                                                                                    label={`${item.isActive ? 'InActive' : 'Active'}`}

                                                                                /> */}






                                                                            </td>

                                                                        </tr>


                                                                    )


                                                                })

                                                            }

                                                        </tbody>
                                                        <tfoot>
                                                            <TablePagination totalPages={totalPages} page={page} setPage={setPage} />
                                                        </tfoot>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </main>

        </>
    )
}

export default Documents