import { useMutation } from '@tanstack/react-query'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import api from '../../../lib/api'
import { Modal } from 'react-bootstrap'
import cogoToast from 'cogo-toast'

const EditCommunity = ({ show, setShow, editData }) => {






  const handleClose = () => {

    setShow(false)

  }



  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm()



  const { mutate } = useMutation({
    mutationFn: (data) => api.post("/web/edit-community", data),
    onMutate: (variables) => {
      // A mutation is about to happen!

      // Optionally return a context containing data to use when for example rolling back
      return { id: 1 };
    },

    onError: (data, error, variables, context) => {
      // An error happened!
      if (data.response.data.message) {

        cogoToast.error(`${data.response.data.message}`);
      } else {
        cogoToast.error(`server error`);

      }


    },
    onSuccess: (data, variables, context) => {
      console.log("variable", variables);
      if (data.data.status == 200) {
        cogoToast.success(`${data?.data?.message}`);
        setTimeout(() => {
          setShow(false)
        }, 1000)

      }
    },
    onSettled: (data, error, variables, context) => {
      // Error or success... doesn't matter!
    },
  });



  useEffect(() => {
    if (Object.values(editData)?.length > 0) {
      setValue("name", editData?.name)

    }


  }, [editData])








  const onSubmit = (data) => {
    const body = {
      name: data.name,
      id: editData?._id



    }


    mutate(body)
  }




  return (

    <Modal show={show} onHide={handleClose}>

      <Modal.Body>
        <main className="content">
          <div>
            {/* <a id="btn-toggle" href="#" className="sidebar-toggler break-point-sm">
                            <i className="ri-menu-line ri-xl"></i>
                        </a> */}
            <div className="container  px-44">
              <div className="row">

                <div className="col-xl-12">
                  <div className="card border-0 py-4 px-2">
                    <h4 className="text-slate mb-3">Edit Community</h4>
                    <div className="card-body  px-0">
                      <form className="common-form" onSubmit={handleSubmit(onSubmit)} >
                        <div className="mb-4">
                          <label className="form-label">Question</label>
                          <input type="text" className="form-control" placeholder="Enter Name" {...register("name", { required: "Name is required" })} />
                          {errors.name && <p style={{ color: 'red' }} role="alert">{errors.title.name}</p>}

                        </div>
                        <button type="submit" className="btn btn-main Shadow">Submit</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Modal.Body>

    </Modal>


  )
}

export default EditCommunity