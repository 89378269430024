import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import api from '../lib/api';
import { useMutation } from '@tanstack/react-query';
import cogoToast from 'cogo-toast';

const DeleteData = ({ show, setShow, deleteType, data }) => {





    // 

    const { mutate: deleteData } = useMutation({
        mutationFn: (data) => api.post("/common/delete-data", data),


        onError: (data, error, variables, context) => {
            // An error happened!
            if (data.response.data.message) {

                cogoToast.error(`${data.response.data.message}`);
            } else {
                cogoToast.error(`server error`);

            }


        },
        onSuccess: (data, variables, context) => {
            console.log("variable", variables);
            cogoToast.success(`${data.data.message}`)
            setShow(false)
        },

    });





    return (
        <>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Are you sure you want to delete?</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        cancel
                    </Button>
                    <Button variant="primary" onClick={() => {

                        deleteData({
                            deleteType, id: data._id

                        })

                    }}>
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>












        </>
    )
}

export default DeleteData