import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import api from '../../lib/api';
import TablePagination from '../../component/TablePagination';
import AddGlobalAnnouncements from './components/AddGlobalAnnouncements';
import dayjs from 'dayjs';
import { useNavBar } from '../../hooks/useNavBarContext';
import cogoToast from 'cogo-toast';

const GlobalAnnouncements = () => {
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [addOpen, setAddOpen] = useState(false)
    const { community, setCommunity, search } = useNavBar()

    const { data: data, refetch } = useQuery({
        queryKey: ["get-announcements", search],
        queryFn: () => api.get(`web/get-announcements?search=${search}&community=${community ?? '#'}`),
        select: (res) => res.data.data,
        enabled: false,
    });

    const { mutate } = useMutation({
        mutationFn: (data) => api.post("/web/send-global-announcements", data),
        onMutate: (variables) => {
            // A mutation is about to happen!

            // Optionally return a context containing data to use when for example rolling back
            return { id: 1 };
        },

        onError: (data, error, variables, context) => {
            // An error happened!
            if (data.response.data.message) {

                cogoToast.error(`${data.response.data.message}`);
            } else {
                cogoToast.error(`server error`);

            }


        },
        onSuccess: (data, variables, context) => {

            console.log("variable", variables);
            cogoToast.success(`${data.data.message}`)


        },
        onSettled: (data, error, variables, context) => {
            // Error or success... doesn't matter!
        },
    });




    useEffect(() => {
        refetch()
    }, [community, search])
    return (
        <>



            {
                addOpen ? <AddGlobalAnnouncements show={addOpen} setShow={setAddOpen} refetch={refetch} /> : <></>

            }

            <main className="content">
                <div>
                    {/* <a id="btn-toggle" href="#" className="sidebar-toggler break-point-sm">
                        <i className="ri-menu-line ri-xl"></i>
                    </a> */}
                    <div className="container px-44">
                        <div className="row">

                            <div className="col-md-12">
                                <div className="card border-0 table-card">
                                    <div className="card-body">
                                        <div className="clearfix mb-4 mt-2">
                                            <div className="float-start">
                                                <h4 className="thm-color">Global Annoucements</h4>
                                            </div>
                                            <div className="float-end">
                                                <button className="btn btn-main me-2" onClick={() => {
                                                    setAddOpen(true)
                                                }}>Add Announcement</button>
                                            </div>
                                        </div>
                                        <div className="table-responsive">
                                            <table className="table table-striped mb-0 align-middle common-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">S no.</th>
                                                        <th scope="col">Date</th>
                                                        <th scope="col">Title</th>
                                                        <th scope="col">Message</th>
                                                        <th scope="col">Type</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        data?.result?.map((item, index) => {
                                                            return (

                                                                <tr key={item._id} >
                                                                    <th scope="row">{index + 1}</th>
                                                                    <td> {dayjs(item?.dateAndTime).format('DD-MM-YYYY')}</td>
                                                                    <td>{item?.title}</td>
                                                                    <td>{item?.message}</td>
                                                                    <td>{item?.announcementType}</td>


                                                                    <td style={{ display: 'flex' }}>
                                                                        <button className="btn btn-send me-2" onClick={() => {
                                                                            mutate({
                                                                                title: item.title,
                                                                                message: item.message
                                                                            })

                                                                        }} >Send</button>






                                                                    </td>

                                                                </tr>


                                                            )


                                                        })

                                                    }
                                                </tbody>
                                                <tfoot>
                                                    <TablePagination totalPages={data?.paginate?.totalPages} page={page} setPage={setPage} />

                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>




        </>
    )
}

export default GlobalAnnouncements