import React, { useEffect } from 'react'
import { useCommon } from '../hooks/useCommon'
import cogoToast from 'cogo-toast'
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes"
const MultiSelectPicker = ({ mapData, hold, limit }) => {

    const { setSelectedUsers, selecteUsers, data, setData } = useCommon()



    useEffect(() => {
        if (mapData?.length > 0 && hold) {
            let tempVal = mapData?.filter((fi) => fi.role == "community_admin")?.map((item) => {
                return {
                    label: item.name,
                    value: item._id
                }
            })
            setData([...tempVal])
            let tempIds = tempVal?.map((item) => item._id)
            setSelectedUsers([...tempIds])

        }

    }, [mapData])



    return (
        <>
            <div className="card-body px-0" >
                <ReactMultiSelectCheckboxes style={{ width: "300px" }} options={mapData?.map((item) => {
                    return {
                        label: item.name, value: item._id


                    }
                })} onChange={(val) => {
                    if (val?.length > limit) {
                        cogoToast.error('only 2 admins are allowed for community')
                    } else {
                        let tempVal = val?.map((item) => item.value)
                        setData([...val])
                        setSelectedUsers([...tempVal])
                    }

                }} value={data} />







            </div>


        </>
    )
}

export default MultiSelectPicker