import { useMutation, useQuery } from '@tanstack/react-query'
import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import cogoToast from 'cogo-toast'
import { useForm } from 'react-hook-form'
import api from '../../../lib/api'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
const AdminAssignModal = ({ show, setShow, refetch, editData }) => {
    const [selectedUsers, setSelectedUsers] = useState([])
    const [data, setData] = useState([])

    const handleClose = () => {

        setShow(false)

    }









    const { mutate } = useMutation({
        mutationFn: (data) => api.post("/web/assign-admin-role", data),
        onMutate: (variables) => {
            // A mutation is about to happen!

            // Optionally return a context containing data to use when for example rolling back
            return { id: 1 };
        },

        onError: (data, error, variables, context) => {
            // An error happened!
            if (data.response.data.message) {

                cogoToast.error(`${data.response.data.message}`);
            } else {
                cogoToast.error(`server error`);

            }


        },
        onSuccess: (data, variables, context) => {
            console.log("variable", variables);
            if (data.data.status == 200) {
                cogoToast.success(`${data?.data?.message}`);
                setTimeout(() => {
                    setShow(false)
                }, 1000)

            }
        },
        onSettled: (data, error, variables, context) => {
            // Error or success... doesn't matter!
        },
    });






    const { data: allUsers } = useQuery({
        queryKey: ["get-all-users", editData],
        queryFn: () => api.get(`common/get-all-users?community=${editData?._id}`),
        select: (res) => res.data.data,
    });




    const onSubmit = (data) => {
        const body = {
            community: editData?._id,
            selectedUsers



        }


        mutate(body)
    }


    useEffect(() => {
        if (allUsers?.length > 0) {
            let tempVal = allUsers?.filter((fi) => fi.role == "community_admin")?.map((item) => {
                return {
                    label: item.name,
                    value: item._id
                }
            })
            setData([...tempVal])
            let tempIds = tempVal?.map((item) => item._id)
            setSelectedUsers([...tempIds])

        }

    }, [allUsers])



    console.log(selectedUsers)


    return (
        <Modal show={show} onHide={handleClose}>

            <Modal.Body>
                <main className="content">
                    <div>
                        {/* <a id="btn-toggle" href="#" className="sidebar-toggler break-point-sm">
                            <i className="ri-menu-line ri-xl"></i>
                        </a> */}
                        <div className="container  px-44">
                            <div className="row">

                                <div className="col-xl-12">
                                    <div className="card border-0 py-4 px-2">
                                        <h4 className="text-slate mb-3">Assign Admin Role</h4>
                                        <div className="card-body  px-0" >
                                            <ReactMultiSelectCheckboxes style={{ width: "300px" }} options={allUsers?.map((item) => {
                                                return {
                                                    label: item.name, value: item._id


                                                }
                                            })} onChange={(val) => {
                                                if (val?.length > 2) {
                                                    cogoToast.error('only 2 admins are allowed for community')
                                                } else {
                                                    let tempVal = val?.map((item) => item.value)
                                                    setData([...val])
                                                    setSelectedUsers([...tempVal])
                                                }

                                            }} value={data} />


                                            <button type="submit" onClick={onSubmit} className="btn btn-main Shadow mt-4">Submit</button>





                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </Modal.Body>

        </Modal>
    )
}

export default AdminAssignModal